export const scrollToElement: (
  element: React.RefObject<HTMLElement>,
  options?: ScrollToOptions
) => void = (element, options) => {
  if (typeof window === `undefined`) return

  if (!element.current) return

  const targetOffsetY = element.current.offsetTop
  const currentScrollY = window.scrollY

  // if scrolling upwards, header will be shown, so subtract its height
  const resultingOffsetY =
    currentScrollY < targetOffsetY
      ? targetOffsetY
      : targetOffsetY - 78 /* TODO: Header Menu height in consts? */

  window.scrollTo({
    top: resultingOffsetY,
    behavior: 'smooth',
    ...options,
  })
}
