import { useRef } from 'react'
import styled from 'styled-components'

import { ContactDetails } from 'components/Contact/ContactDetails'
import { ContactForm } from 'components/Contact/ContactForm'
import GoogleMap from 'components/GoogleMap/GoogleMap'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import { Box, Grid } from 'ui/common'
import SectionTitle from 'ui/Section/SectionTitle'

const ContactPage: React.FC = () => {
  const mapContainerRef = useRef<HTMLDivElement>(null)

  return (
    <Layout>
      <SEO title="Kontakt" />

      <SectionTitle title="Kontakt" uppercase mt="xl" />
      <Grid
        maxWidth={{ _: '90%', monitor: '1024px', monitorL: '1024px' }}
        marginX="auto"
        mb="xxl"
        gridTemplateColumns={{ _: '1fr', tablet: '3fr 4fr' }}
        gridGap={{ _: '2rem', tablet: '3rem', monitorS: '4rem' }}
      >
        <ContactDetails mapContainerRef={mapContainerRef} />
        <ContactForm />
      </Grid>
      <GoogleMapWrapper ref={mapContainerRef}>
        <GoogleMap />
      </GoogleMapWrapper>
    </Layout>
  )
}

export default ContactPage

const GoogleMapWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 400px;
  max-width: 100%;
  resize: none;
`
GoogleMapWrapper.displayName = 'GoogleMapWrapper'
