import { NavigateFn, NavigateOptions } from '@reach/router'
import { navigate } from 'gatsby'

export const navigateSSRSafe: NavigateFn = (
  to: string | number,
  options?: NavigateOptions<Record<string, unknown>>
) => {
  if (typeof window === 'undefined')
    return new Promise<void>((resolve, reject) => {
      return reject()
    })

  if (typeof to === 'number') return navigate(to)

  return navigate(to, options)
}
