import styled from 'styled-components'

import { Box } from 'ui/common'

const MAP_SRC =
  'https://maps.google.com/maps?q=Kazimierzowska+18A,+05-807+Owczarnia&hl=pl&z=13&ie=UTF8'

const GoogleMapPlaceholder: React.FC = () => (
  <StyledIframe as={Box} backgroundColor="gray400">
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box>[wersja deweloperska]</Box>
      <a href={MAP_SRC} target="_blank" rel="noopener noreferrer nofollow">
        Podgląd MAPY
      </a>
    </Box>
  </StyledIframe>
)

const GoogleMap: React.FC = () => {
  if (process.env.NODE_ENV === 'development') return <GoogleMapPlaceholder />

  return (
    <StyledIframe
      allow="fullscreen"
      allowFullScreen
      src={`${MAP_SRC}&output=embed`}
      title="Google Maps - Cuda Kwiatki"
    />
  )
}

export default GoogleMap

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  object-position: 50% 50%;
  background-color: ${p => p.theme.colors.gray400};
`
