import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import { Link } from 'components/Link/Link'
import { SITE_HOSTNAME } from 'constants/texts/company'
import { Checkbox, CheckboxProps } from 'ui/Checkbox/Checkbox'

interface ContactFormAcceptanceProps
  extends Pick<CheckboxProps, 'checked' | 'onChange'> {}

export const ContactFormAcceptance: React.FC<ContactFormAcceptanceProps> = ({
  checked,
  onChange,
}) => (
  <StaticQuery
    query={formAcceptanceQuery}
    render={data => (
      <AcceptanceFormField>
        <p>
          OŚWIADCZAM, ŻE ZAPOZNAŁAM/-ŁEM SIĘ Z{' '}
          <Link
            to={`https://${SITE_HOSTNAME}/polityka-prywatnosci`}
            linkType="external"
            target="_blank"
          >
            POLITYKĄ PRYWATNOŚCI
          </Link>{' '}
          ORAZ WYRAŻAM ZGODĘ NA PRZETWARZANIE MOICH DANYCH OSOBOWYCH PODANYCH W
          POWYŻSZYM FORMULARZU W CELACH HANDLOWYCH I MARKETINGOWYCH PRZEZ{` `}
          {data.site.siteMetadata.owner || SITE_HOSTNAME} ORAZ OTRZYMYWANIE
          DROGĄ ELEKTRONICZNĄ OFERTY POCHODZĄCEJ OD WW. FIRMY.
        </p>
        <Checkbox
          name="acceptance-marketing"
          checked={checked}
          required
          onChange={onChange}
        >
          Wyrażam zgodę
        </Checkbox>
      </AcceptanceFormField>
    )}
  />
)

export const formAcceptanceQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const AcceptanceFormField = styled.div`
  margin: ${p => p.theme.space.s}px 0 ${p => p.theme.space.l}px;

  p {
    margin: ${p => p.theme.space.l}px 0 ${p => p.theme.space.s}px;
    font-size: 80%;
    text-transform: uppercase;
    text-align: justify;
  }

  p > a {
    font-size: inherit;
  }
`
