import styled, { css } from 'styled-components'

import { Flex, FlexProps, Text } from 'ui/common'

interface SectionTitleProps extends FlexProps {
  title: string
  uppercase?: boolean
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  uppercase,
  ...rest
}) => (
  <Flex width="100%" marginBottom="xl" justifyContent="center" {...rest}>
    <SectionTitleText
      forwardedAs="h2"
      fontSize={{ _: 'm', mobileL: 'l', tablet: 'xl' }}
      uppercase={uppercase}
    >
      {title}
    </SectionTitleText>
  </Flex>
)

export default SectionTitle

interface SectionTitleTextProps {
  uppercase?: boolean
}

const SectionTitleText = styled(Text)<SectionTitleTextProps>`
  position: relative;
  width: max-content;
  text-align: center;
  letter-spacing: 0.3rem;
  margin: 0 7rem;
  ${p => css`
    font-weight: ${p.theme.fontWeights.semiBold};
    max-width: ${p.theme.breakpoints.tablet};
    text-transform: ${p.uppercase ? 'uppercase' : undefined};
  `}

  @media ${p => p.theme.devices.tablet.max} {
    width: 100%;
    margin: 0;
  }
`
