import styled from 'styled-components'

export interface CheckboxProps {
  name: string
  checked?: boolean
  required?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  name,
  checked,
  required,
  onChange,
}) => {
  return (
    <CheckboxLabel htmlFor={name}>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        required={required}
        onChange={onChange}
      />
      <span className="checkmark" />
      {children}
    </CheckboxLabel>
  )
}

const CheckboxLabel = styled.label`
  cursor: pointer;
  position: relative;
  padding-left: ${p => p.theme.space.xl}px;

  &:hover {
    text-decoration: underline;

    .checkmark {
      background: ${p => p.theme.colors.gray400};
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: ${p => p.theme.colors.gray300};
    border-radius: 3px;
    border-left: 3px solid ${p => p.theme.colors.gray400};
  }

  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(40deg);
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    bottom: 0;
    left: 0.5rem;

    &:checked {
      & ~ .checkmark {
        border-width: 0;
        background: ${p => p.theme.colors.positive};

        &::after {
          display: block;
        }
      }
    }
  }
`
