import { useCallback } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  CONTACT_ADDRESS_CONTENT,
  CONTACT_ADDRESS_LABEL,
  CONTACT_COMPANY_NAME_FULL,
  CONTACT_EMAIL_ADDRESS,
  CONTACT_EMAIL_LABEL,
  CONTACT_PHONE_LABEL,
  CONTACT_PHONE_NUMBER,
  CONTACT_PHONE_NUMBER_DISPLAYED,
} from 'constants/texts/contact'
import { Flex, Text, TextButton } from 'ui/common'
import { Mail, Phone, Pin } from 'ui/icons'
import { scrollToElement } from 'utils/scrollToElement'

interface ContactDetailsProps {
  mapContainerRef: React.RefObject<HTMLElement>
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({
  mapContainerRef,
}) => {
  const theme = useTheme()

  const scrollToMap = useCallback(() => {
    if (mapContainerRef) scrollToElement(mapContainerRef)
  }, [mapContainerRef])

  return (
    <div>
      <Text as="h3" mb="xl">
        {CONTACT_COMPANY_NAME_FULL}
      </Text>
      <ItemsContainer>
        <ContactDetailsItem mb="l">
          <Text mr="xs" flexShrink={0}>
            <Pin width={20} height={20} color={theme.colors.accent} />
            <Text as="strong" ml="xxs" verticalAlign="middle">
              {CONTACT_ADDRESS_LABEL}:
            </Text>
          </Text>
          <StyledTextButton forwardedAs="button" onClick={scrollToMap}>
            {CONTACT_ADDRESS_CONTENT}
          </StyledTextButton>
        </ContactDetailsItem>
        <ContactDetailsItem mb="l">
          <Text mr="xs" flexShrink={0}>
            <Phone width={20} height={20} color={theme.colors.accent} />
            <Text as="strong" ml="xxs" verticalAlign="middle">
              {CONTACT_PHONE_LABEL}:
            </Text>
          </Text>
          <a href={`tel:${CONTACT_PHONE_NUMBER}`}>
            <StyledTextButton>
              {CONTACT_PHONE_NUMBER_DISPLAYED}
            </StyledTextButton>
          </a>
        </ContactDetailsItem>
        <ContactDetailsItem>
          <Text mr="xs" flexShrink={0}>
            <Mail width={20} height={20} color={theme.colors.accent} />
            <Text as="strong" ml="xxs" verticalAlign="middle">
              {CONTACT_EMAIL_LABEL}:
            </Text>
          </Text>
          <a href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>
            <StyledTextButton>{CONTACT_EMAIL_ADDRESS}</StyledTextButton>
          </a>
        </ContactDetailsItem>
      </ItemsContainer>
    </div>
  )
}

const ItemsContainer = styled.div`
  overflow: hidden;
  box-shadow: 0 1px 4px 0 #00000033;
  transition: all ${({ theme }) => theme.transitions.quick};
  max-width: 100%;
  padding: 2rem 1.5rem;

  @media (max-width: 480px) {
    box-shadow: 0 0 4px 0 #00000033;
    padding: 1.5rem 1rem;
  }
`

const ContactDetailsItem = styled(Flex)`
  align-items: flex-start;
`

const StyledTextButton = styled(TextButton)`
  overflow-wrap: break-word;
  text-align: left;
`
