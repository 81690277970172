import { createRef, useState } from 'react'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import { format } from 'date-fns'

import { SITE_HOSTNAME } from 'constants/texts/company'
import { Box, Button, Grid, Text } from 'ui/common'
import { navigateSSRSafe } from 'utils/navigateSSRSafe'
import { ContactFormAcceptance } from './ContactFormAcceptance'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
const CONTACT_FORM_NAME = 'contact-form'
const FORM_SUCCESS_PAGE = '/dziekujemy/'
const CURRENT_DAY = new Date()

const encode = <T extends Record<string, string | number | boolean>>(
  data: T
) => {
  return Object.keys(data)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          data[key as keyof T]
          // for form encoding spaces should be replaced with "+"
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
        ).replace(/%20/g, '+')}`
    )
    .join('&')
}

interface FormData {
  name: string
  email: string
  phone: string
  eventDate: string
  message: string
}

export const ContactForm: React.FC = () => {
  const formRef = createRef<HTMLFormElement>()
  const recaptchaRef = createRef<ReCAPTCHA>()

  const [isFormValid, setIsFormValid] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    eventDate: '',
    message: '',
  })
  const [acceptance, setAcceptance] = useState(false)

  if (!RECAPTCHA_KEY)
    return (
      <Box>
        <Text>Formularz niedostępny</Text>
      </Box>
    )

  const onAcceptanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'acceptance-marketing') {
      setAcceptance(e.target.checked)
    }
  }

  const onChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmitHandle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const form = e.target as HTMLFormElement

    if (!recaptchaRef.current) return

    const token = await recaptchaRef.current.executeAsync()

    if (!token) return

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name') || CONTACT_FORM_NAME,
          'g-recaptcha-response': token,
          'acceptance-marketing': acceptance,
          ...formData,
          subject: `[${formData.eventDate}] Zapytanie od: ${formData.name}`,
        }),
      })

      await navigateSSRSafe(form.getAttribute('action') || FORM_SUCCESS_PAGE, {
        state: { fromForm: true },
        replace: true,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error when posting form data: ${JSON.stringify(formData)}`)
    }
  }

  const validateAllInputs = () => {
    if (!formRef?.current) return
    setIsFormValid(formRef.current.checkValidity())
  }

  const { email, eventDate, message, name, phone } = formData

  return (
    <FormLayout>
      <Text as="h3" mb="m">
        Napisz do nas
      </Text>
      <Text mb="s">
        Jeśli chcesz zapytać o dostępność dekoracji lub chcesz, żebyśmy
        skontaktowali się z Tobą, prosimy o przesłanie wiadomości poprzez
        poniższy formularz.
      </Text>
      <Text mb="s">
        Wprowadź swoje dane kontaktowe i opisz, jakich dekoracji potrzebujesz.
        Jeśli chcesz zapytać o dostępność na więcej niż jeden możliwy termin lub
        na dłuższy okres, pamiętaj o uwzględnieniu tego w wiadomości.
      </Text>

      <form
        name="contact-form"
        method="post"
        action="/dziekujemy"
        ref={formRef}
        className={isFormValid ? 'form-valid' : ''}
        autoComplete="off"
        data-netlify="true" // handle forms in Netlify Submissions
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        onChange={validateAllInputs}
        onSubmit={onSubmitHandle}
      >
        {/* <input type="hidden" name="form-name" value="contact-form" /> */}
        <FormField>
          <label htmlFor="name">
            Twoje imię
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              placeholder="(podaj imię)"
              required
              onChange={onChangeHandle}
            />
          </label>
        </FormField>
        <FormField>
          <label htmlFor="email">
            Email
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              placeholder="(wymagane, żebyśmy mogli udzielić odpowiedzi)"
              required
              onChange={onChangeHandle}
            />
          </label>
        </FormField>
        <Grid
          gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
          gridColumnGap="xxl"
        >
          <FormField>
            <label htmlFor="eventDate">
              Data wydarzenia
              <input
                type="date"
                name="eventDate"
                id="eventDate"
                value={eventDate}
                min={format(CURRENT_DAY, 'yyyy-MM-dd')}
                required
                onChange={onChangeHandle}
              />
            </label>
          </FormField>
          <FormField className="optional">
            <label htmlFor="phone">
              Telefon
              <input
                type="tel"
                name="phone"
                id="phone"
                value={phone}
                placeholder="+48 | "
                pattern="[0-9() +-]{9,21}"
                title="Sprawdź, czy poprawnie wpisano numer. Dopuszczalne są cyfry od 0 do 9, myślniki, spacje, nr kierunkowy. "
                onChange={onChangeHandle}
              />
            </label>
          </FormField>
        </Grid>
        <FormField>
          <label htmlFor="message">
            Jak możemy Ci pomóc?
            <textarea
              id="message"
              name="message"
              rows={5}
              value={message}
              placeholder="Twoja wiadomość"
              required
              onChange={onChangeHandle}
            />
          </label>
        </FormField>
        <ContactFormAcceptance
          checked={acceptance}
          onChange={onAcceptanceChange}
        />
        <input
          type="hidden"
          id="subject"
          name="subject"
          value={`Zapytanie z formularza kontaktowego ${SITE_HOSTNAME}`}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          size="invisible"
          hl="pl"
        />
        <FormField>
          <Button
            variant="solid"
            variantColor="positive"
            isDisabled={!isFormValid}
          >
            Wyślij
          </Button>
        </FormField>
      </form>
    </FormLayout>
  )
}

const FormLayout = styled.div`
  display: flex;
  flex-direction: column;

  div.grecaptcha-badge {
    z-index: 1;
  }
`

const FormField = styled.div`
  margin-top: ${p => p.theme.space.s}px;

  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='tel'],
  textarea {
    display: block;
    width: 100%;
    line-height: 1.5;
    padding: ${p => p.theme.space.xs}px ${p => p.theme.space.m}px;
    border: 1px solid ${p => p.theme.colors.gray400};
    border-left: 4px solid ${p => p.theme.colors.gray400};

    &:hover {
      border-color: ${p => p.theme.colors.gray700};
    }

    &:required:not(:focus) {
      border-left-color: ${p => p.theme.colors.gray600};
    }

    &:required:valid,
    &:not(:required):focus:valid {
      border-left-color: ${p => p.theme.colors.positive};
    }

    &:focus:invalid {
      border-left-color: ${p => p.theme.colors.warning};
    }
  }

  textarea {
    resize: vertical;
  }

  label {
    text-transform: uppercase;
  }

  input::placeholder,
  input::-webkit-input-placeholder {
    color: #444;
    font-size: 80%;
  }

  input[type='date']::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }

  &.optional {
    label {
      display: block;
      position: relative;
    }

    label::after {
      content: ' (opcjonalnie)';
      position: absolute;
      right: 0;
      top: 0.5em;
      font-style: italic;
      font-size: 70%;
    }
  }
`
